require('./bootstrap');
import '@fortawesome/fontawesome-free/js/all';
//import "datatables.net";
import "datatables.net-bs5";
import 'select2';
import 'sweetalert2';
require ('./library/slick.min');


/** CKEditor 5*/
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
window.ClassicEditor = ClassicEditor;

